<template>
    <terms-dialog v-model="selected" v-bind="{ code, title, single }" @isLoaded="setTermsList" @input="(value) => $emit('input', value)" @isValid="(isValid) => $emit('isValid', isValid)">
        <template v-slot:activator="{ on, isValid }">
            <v-sheet v-on="on" color="transparent" v-bind="$attrs">
                <template v-if="single">
                    <v-row align="center" style="cursor: pointer">
                        <v-col>
                            <v-checkbox label="I agree with the policy" hide-details color="primary" on-icon="mdi-checkbox-outline" :value="isValid" readonly />
                        </v-col>
                        <v-col cols="auto">
                            <span style="text-decoration: underline"> {{ termsList?.[0]?.subject }} </span>
                        </v-col>
                    </v-row>
                </template>
                <template v-else>
                    <v-checkbox class="mb-12" label="I agree with all policies" hide-details color="primary" on-icon="mdi-checkbox-outline" :value="isValid" readonly />
                    <v-card outlined color="transparent" rounded="lg" class="min-h-80px" v-if="termsList.length">
                        <div class="pa-12 pa-md-20">
                            <v-checkbox v-for="terms in termsList" :key="terms._id" :label="`[${terms.necessary ? 'Mandatory' : 'Optional'}] I agree with ${terms.subject}`" :value="selected.includes(terms._id)" hide-details readonly />
                        </div>
                    </v-card>
                </template>
            </v-sheet>
        </template>
    </terms-dialog>
</template>

<script>
import TermsDialog from "./terms-dialog.vue";

export default {
    components: {
        TermsDialog,
    },
    props: {
        value: { type: Array, default: () => [] },

        code: { type: [String, Array], default: null },
        title: { type: String, default: null },
        single: { type: Boolean, default: false },
    },
    data: () => ({
        termsList: [],
        selected: [],
    }),
    methods: {
        setTermsList(termsList) {
            this.termsList = termsList;
        },
    },
};
</script>

<style>
</style>