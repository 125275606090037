<template>
    <v-form v-model="isValid">
        <v-row class="ma-n10">
            <v-col cols="12" class="pa-10">
                <!-- First Name / Last Name -->
                <form-name-input v-model="form.meta" outlined hideTitle hideMiddle @input="emit" />
            </v-col>
            <v-col cols="12" class="pa-10">
                <!-- E-mail address -->
                <v-text-field v-model="form.meta.email" type="email" outlined hide-details="auto" @input="emit">
                    <template #label> E-mail address <span style="color: #ff0000">*</span> </template>
                </v-text-field>
            </v-col>
            <v-col cols="12" class="pa-10">
                <!-- Your inquiry -->
                <v-textarea v-model="form.content" outlined auto-grow rows="6" hide-details="auto" @input="emit">
                    <template #label> Your inquiry <span style="color: #ff0000">*</span> </template>
                </v-textarea>
            </v-col>
        </v-row>

        <!-- Terms -->
        <div class="mt-10">
            <terms-of-agreements-short v-model="form.meta._terms" single code="privacy-policy" @isValid="(isValid) => (isTermsValid = isValid)" @input="emit" />
        </div>

        <!-- 하단버튼 -->
        <div class="mt-30 mt-md-50">
            <div class="v-btn--group v-btn--group--large">
                <v-btn color="secondary" class="w-100 mw-200px" :disabled="!isValid" @click="save">Submit</v-btn>
            </div>
        </div>
    </v-form>
</template>

<script>
import { initForm } from "@/assets/variables";

import FormNameInput from "@/components/client/join/skin/PERSON/form-name-input.vue";
import TermsOfAgreementsShort from "@/components/client/join/terms-of-agreements-short.vue";

export default {
    components: {
        FormNameInput,
        TermsOfAgreementsShort,
    },
    props: {
        value: { type: Object, default: initForm },
    },
    data: () => ({
        form: initForm(),
        isValid: false,
        isTermsValid: undefined,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initForm(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
        validate() {
            try {
                if (!this.form?.meta?.name_first) throw new Error("We need your FIRST NAME to continue with your inquiry");
                if (!this.form?.meta?.name_last) throw new Error("We need your LAST NAME to continue with your inquiry");
                if (!this.form?.meta?.email) throw new Error("We need your EMAIL ADDRESS to continue with your inquiry");
                if (!this.form?.content) throw new Error("You haven't fill in YOUR INQUIRY");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        save() {
            if (!this.validate()) return;
            this.emit();
            this.$emit("save");
        },
    },
};
</script>
