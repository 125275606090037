var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('terms-dialog', _vm._b({
    on: {
      "isLoaded": _vm.setTermsList,
      "input": function (value) {
        return _vm.$emit('input', value);
      },
      "isValid": function (isValid) {
        return _vm.$emit('isValid', isValid);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var _vm$termsList, _vm$termsList$;
        var on = _ref.on,
          isValid = _ref.isValid;
        return [_c('v-sheet', _vm._g(_vm._b({
          attrs: {
            "color": "transparent"
          }
        }, 'v-sheet', _vm.$attrs, false), on), [_vm.single ? [_c('v-row', {
          staticStyle: {
            "cursor": "pointer"
          },
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', [_c('v-checkbox', {
          attrs: {
            "label": "I agree with the policy",
            "hide-details": "",
            "color": "primary",
            "on-icon": "mdi-checkbox-outline",
            "value": isValid,
            "readonly": ""
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('span', {
          staticStyle: {
            "text-decoration": "underline"
          }
        }, [_vm._v(" " + _vm._s((_vm$termsList = _vm.termsList) === null || _vm$termsList === void 0 ? void 0 : (_vm$termsList$ = _vm$termsList[0]) === null || _vm$termsList$ === void 0 ? void 0 : _vm$termsList$.subject) + " ")])])], 1)] : [_c('v-checkbox', {
          staticClass: "mb-12",
          attrs: {
            "label": "I agree with all policies",
            "hide-details": "",
            "color": "primary",
            "on-icon": "mdi-checkbox-outline",
            "value": isValid,
            "readonly": ""
          }
        }), _vm.termsList.length ? _c('v-card', {
          staticClass: "min-h-80px",
          attrs: {
            "outlined": "",
            "color": "transparent",
            "rounded": "lg"
          }
        }, [_c('div', {
          staticClass: "pa-12 pa-md-20"
        }, _vm._l(_vm.termsList, function (terms) {
          return _c('v-checkbox', {
            key: terms._id,
            attrs: {
              "label": `[${terms.necessary ? 'Mandatory' : 'Optional'}] I agree with ${terms.subject}`,
              "value": _vm.selected.includes(terms._id),
              "hide-details": "",
              "readonly": ""
            }
          });
        }), 1)]) : _vm._e()]], 2)];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, 'terms-dialog', {
    code: _vm.code,
    title: _vm.title,
    single: _vm.single
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }