var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-row', {
    staticClass: "ma-n10"
  }, [_c('v-col', {
    staticClass: "pa-10",
    attrs: {
      "cols": "12"
    }
  }, [_c('form-name-input', {
    attrs: {
      "outlined": "",
      "hideTitle": "",
      "hideMiddle": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form.meta,
      callback: function ($$v) {
        _vm.$set(_vm.form, "meta", $$v);
      },
      expression: "form.meta"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-10",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "email",
      "outlined": "",
      "hide-details": "auto"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" E-mail address "), _c('span', {
          staticStyle: {
            "color": "#ff0000"
          }
        }, [_vm._v("*")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.meta.email,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "email", $$v);
      },
      expression: "form.meta.email"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-10",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "6",
      "hide-details": "auto"
    },
    on: {
      "input": _vm.emit
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_vm._v(" Your inquiry "), _c('span', {
          staticStyle: {
            "color": "#ff0000"
          }
        }, [_vm._v("*")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form.content,
      callback: function ($$v) {
        _vm.$set(_vm.form, "content", $$v);
      },
      expression: "form.content"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-10"
  }, [_c('terms-of-agreements-short', {
    attrs: {
      "single": "",
      "code": "privacy-policy"
    },
    on: {
      "isValid": function (isValid) {
        return _vm.isTermsValid = isValid;
      },
      "input": _vm.emit
    },
    model: {
      value: _vm.form.meta._terms,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "_terms", $$v);
      },
      expression: "form.meta._terms"
    }
  })], 1), _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('div', {
    staticClass: "v-btn--group v-btn--group--large"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-200px",
    attrs: {
      "color": "secondary",
      "disabled": !_vm.isValid
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Submit")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }