var render = function render(){
  var _vm$termsList, _vm$termsList$;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550",
      "content-class": "rounded-0"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on,
          isValid: _vm.isValid
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "column": "",
      "fill-height": ""
    }
  }, [_c('v-card-title', {
    staticStyle: {
      "position": "relative"
    }
  }, [_vm._v(" " + _vm._s(_vm.dialogTitle) + " "), _c('v-spacer'), _c('v-icon', {
    on: {
      "click": function ($event) {
        _vm.shows = false;
      }
    }
  }, [_vm._v(" mdi-close ")])], 1), _c('v-card-text', {
    staticStyle: {
      "flex": "1 1 auto",
      "overflow-y": "auto"
    }
  }, [_vm.single ? [_c('v-sheet', [_c('v-card', {
    staticClass: "mt-8",
    staticStyle: {
      "height": "240px",
      "max-height": "20vh",
      "overflow-y": "auto"
    },
    attrs: {
      "tile": "",
      "outlined": ""
    }
  }, [_c('div', {
    staticClass: "pa-12"
  }, [_c('p', {
    staticClass: "page-text--sm grey--text",
    domProps: {
      "innerHTML": _vm._s((_vm$termsList = _vm.termsList) === null || _vm$termsList === void 0 ? void 0 : (_vm$termsList$ = _vm$termsList[0]) === null || _vm$termsList$ === void 0 ? void 0 : _vm$termsList$.content)
    }
  })])])], 1)] : [_c('v-row', {
    staticClass: "my-0",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-checkbox', {
    staticClass: "mr-4",
    attrs: {
      "label": "I agree with all policies",
      "readonly": "",
      "hide-details": ""
    },
    on: {
      "click": _vm.toggleSelectedAll
    },
    model: {
      value: _vm.isValid,
      callback: function ($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  })], 1)], 1), _c('v-divider'), _vm._l(_vm.termsList, function (term) {
    return _c('v-sheet', {
      key: term._id,
      staticClass: "mt-12"
    }, [_c('v-row', {
      staticClass: "my-0"
    }, [_c('v-col', [_c('h4', {
      staticClass: "page-text--lg"
    }, [_vm._v(_vm._s(term.subject))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-checkbox', {
      attrs: {
        "value": term._id,
        "label": `I agree with the ${term.subject}`,
        "hide-details": ""
      },
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    })], 1)], 1), _c('v-card', {
      staticClass: "mt-8",
      staticStyle: {
        "height": "240px",
        "max-height": "20vh",
        "overflow-y": "auto"
      },
      attrs: {
        "rounded": "",
        "outlined": ""
      }
    }, [_c('div', {
      staticClass: "pa-12"
    }, [_c('p', {
      staticClass: "page-text--sm grey--text",
      domProps: {
        "innerHTML": _vm._s(term.content)
      }
    })])])], 1);
  })]], 2), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_vm.single ? [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.agreeSingle
    }
  }, [_c('span', [_vm._v("I agree the policy")])])] : [_c('v-btn', {
    staticClass: "min-w-140px min-w-lg-160px",
    attrs: {
      "color": "secondary"
    },
    on: {
      "click": _vm.agree
    }
  }, [_c('span', [_vm._v("Submit")])])]], 2)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }