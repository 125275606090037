<template>
    <v-dialog v-model="shows" persistent max-width="550" content-class="rounded-0">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, isValid }" />
        </template>

        <v-card tile>
            <v-layout column fill-height>
                <v-card-title style="position: relative">
                    {{ dialogTitle }} <v-spacer />
                    <v-icon @click="shows = false"> mdi-close </v-icon>
                </v-card-title>

                <v-card-text style="flex: 1 1 auto; overflow-y: auto">
                    <template v-if="single">
                        <v-sheet>
                            <v-card tile outlined class="mt-8" style="height: 240px; max-height: 20vh; overflow-y: auto">
                                <div class="pa-12">
                                    <p class="page-text--sm grey--text" v-html="termsList?.[0]?.content" />
                                </div>
                            </v-card>
                        </v-sheet>
                    </template>
                    <template v-else>
                        <v-row justify="end" class="my-0">
                            <v-col cols="auto"> <v-checkbox v-model="isValid" label="I agree with all policies" readonly @click="toggleSelectedAll" hide-details class="mr-4" /> </v-col>
                        </v-row>
                        <v-divider />
                        <v-sheet v-for="term in termsList" :key="term._id" class="mt-12">
                            <v-row class="my-0">
                                <v-col>
                                    <h4 class="page-text--lg">{{ term.subject }}</h4>
                                </v-col>
                                <v-col cols="auto">
                                    <v-checkbox v-model="selected" :value="term._id" :label="`I agree with the ${term.subject}`" hide-details />
                                </v-col>
                            </v-row>
                            <v-card rounded outlined class="mt-8" style="height: 240px; max-height: 20vh; overflow-y: auto">
                                <div class="pa-12">
                                    <p class="page-text--sm grey--text" v-html="term.content" />
                                </div>
                            </v-card>
                        </v-sheet>
                    </template>
                </v-card-text>

                <v-card-actions class="justify-center">
                    <template v-if="single">
                        <v-btn color="secondary" @click="agreeSingle" class="min-w-140px min-w-lg-160px"><span>I agree the policy</span></v-btn>
                    </template>
                    <template v-else>
                        <v-btn color="secondary" @click="agree" class="min-w-140px min-w-lg-160px"><span>Submit</span></v-btn>
                    </template>
                </v-card-actions>
            </v-layout>
        </v-card>
    </v-dialog>
</template>

<script>
import api from "@/api";
// import { attrs_input } from "@/assets/variables";

import VPreviewInput from "@/components/plugins/vuetify/v-preview-input.vue";

export default {
    components: {
        VPreviewInput,
    },
    props: {
        value: { type: Array, default: () => [] },

        code: { type: [Array, String], default: null },
        title: { type: String, default: null },
        single: { type: Boolean, default: false },
    },
    data: () => ({
        shows: false,
        isLoaded: false,
        selected: [],
        termsList: [],

        // attrs_input,
    }),
    computed: {
        dialogTitle() {
            return this.title || (this.single ? this.termsList[0]?.subject || "Policy" : "Policies");
        },
        params() {
            const params = {};
            if (this.code) params.code = this.code;
            return params;
        },
        isValid() {
            if (this.single) return this.selected.includes(this.termsList?.[0]?._id);
            else return !this.termsList.some(({ _id }) => !this.selected.includes(_id));
        },
    },
    created() {
        this.init();
    },
    mounted() {
        this.selected = this.value;
    },
    watch: {
        shows() {
            if (this.shows) this.init();
        },

        isLoaded(isLoaded) {
            if (isLoaded) this.$emit("isLoaded", this.termsList);
        },
    },
    methods: {
        async init() {
            try {
                this.isLoaded = false;

                const { params } = this;
                var { termsList } = await api.v1.terms.gets({ params });
                this.termsList = termsList;
            } finally {
                this.isLoaded = true;
            }
        },

        toggleSelectedAll() {
            if (this.isValid) this.selected = [];
            else this.selected = this.termsList.map(({ _id }) => _id);
        },
        cancel() {
            this.selected = this.value;
            this.shows = false;
        },
        agree() {
            this.$emit("input", this.selected);
            this.$emit("isValid", this.isValid);
            this.shows = false;
        },
        agreeSingle() {
            this.selected = [this.termsList?.[0]?._id].filter((item) => item);

            this.$emit("input", this.selected);
            this.$emit("isValid", this.isValid);
            this.shows = false;
        },
    },
};
</script>

<style></style>
